import React, { forwardRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import LoadingOverlay from 'react-loading-overlay-ts';

import { t, i18ndb } from '../services';
import { appContext } from '../AppContext';


import './table.css'
import SearchBar from 'material-ui-search-bar';

const translateColumnHeaders =(columnHeaders)=>{
	let translated = [];
	columnHeaders.forEach(c => {
		c.title =  i18ndb._(c.title);
		translated.push(c);
	})
	return translated;
}

export const createTableComponent = (fields) =>  class TableComponent extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			loading: false,
			loadingMessage: undefined,
			totalCount:undefined,
		}
    this.tableRef = React.createRef();
    this.search = undefined;
		this.columns = translateColumnHeaders(fields);
	    this.cssProperties = {};
	    if (appContext.getLanguage() === "ar")
	      this.cssProperties['direction'] = 'rtl';
	    else
	      this.cssProperties['direction'] = 'ltr';
	}

	render() {
	    return (
		<LoadingOverlay
				active={this.state.loading}
				spinner
				text={this.state.loadingMessage}
			>
        <div className="table" style={this.cssProperties}>
         
          <MaterialTable
            tableRef={this.tableRef}
            components={(this.props.noSearch || this.props.localData)?null:{Toolbar: toolbarProps => {
              return (
                <>
                  <div className="row">
                  <MTableToolbar {...toolbarProps} />
                    <SearchBar
                      style={{marginRight: '1rem', marginLeft: 'auto'}}
                      onRequestSearch={(searchText) => {
                        this.search = searchText;
                        this.tableRef.current.onQueryChange();
                      }}
                      
                    />
                  </div>
                </>)
              }}}
            columns={this.columns}
            data= {this.props.localData == true ? this.props.loadData : query => new Promise(
                  (resolve, reject) => {
                      this.setState({loading: true});
                      this.props.loadData(
                          {limit: query.pageSize, page: query.page, pageSize: query.pageSize,
                              search: this.search, orderBy: query.orderBy, orderDirection: query.orderDirection}
                      ).then(
                          loaded => {
                              this.setState({loading: false, totalCount: loaded.totalCount});
                              resolve(loaded);
                          }
                      )
                  }
              )
            }

            actions={this.props.actions}
            editable={this.props.editable}

            options={{
                filtering: false,
                actionsColumnIndex: -1,
                search:(this.props.localData)?true:false,
                showTitle:false,
                headerStyle:{
                  backgroundColor:'#01579b',
                  color: '#FFF',
                  textAlign:appContext.getLanguage() == "ar"?'right':'left',
                },
                filterCellStyle: {
                  maxWidth:"100px",
                },
                cellStyle:{
                  textAlign:appContext.getLanguage() == "ar"?'right':'left',
                },
                paging:true,
                pageSize:(this.state.totalCount < 20)?this.state.totalCount:20,
                pageSizeOptions:[5,10,20,50],
            }}

            localization={{
                 body: {
                  emptyDataSourceMessage: t`no data`,
                  addTooltip:t`Add`,
                  deleteTooltip:t`Delete`,
                  editTooltip:t`Edit`,
                  editRow:{
                    deleteText:t`Delete this item?`,
                    cancelTooltip: t`cancel`,
                 //   saveTooltip: t`Save`
                  }
                },
                toolbar: {
                  searchTooltip: t`Search`,
                  searchPlaceholder:t`Search`
                },
                header:{
                  actions: t`action_type`
                },
                pagination: {
                  labelRowsSelect: t`row_pk`,
                  labelDisplayedRows: '{from}-{to} '+ t`of` + ' {count}',
                  firstTooltip: t`first page`,
                  previousTooltip: t`previous`,
                  nextTooltip: t`next`,
                  lastTooltip: t`last page`
                }
              }}
          />
        </div>
     </LoadingOverlay>);
	}
} 