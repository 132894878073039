import React from 'react';

import { store } from '../store';
import { push } from '../redux/BrowserNavigation';
import {AlertDialog} from '../widgets';
import { landingPage } from '../metadata';
import { t } from './i18ndb'

export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.state = {openAlert: false};
    }
  
    componentDidCatch() {
      // Display fallback UI
      this.setState({ hasError: true });
      this.setState({ openAlert: true});
      // You can also log the error to an error reporting service
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        if(!this.state.openAlert) {
            store.dispatch(push(landingPage));
            return this.props.children;
        }
        return (
            <>
                <AlertDialog
                    confirmMessage={t`Communication failed with the server, please check your internet connection, you will be redirected to the home page`}
                    handleClose={() => {
                        this.setState({openAlert: false});
                    }}
                    open={this.state.openAlert}
                    agree={() => {
                        this.setState({openAlert: false});
                    }}
                    noCancel={true}
                /> 
            </>
        );
      }
      return this.props.children;
    }
  }