import React from "react";
import { whoami } from "../../../auto/js/users/UserInfo";
import MediaCard from "../widgets/MediaCard";

export class Welcome extends React.Component {
	constructor(props) {
		super(props);
	}
	
	render() {
	    return (
	        <>
	        <h1 style = {{ textAlign: "center", marginTop: "10px", marginBottom: "100px" }}> Welcome  {whoami().firstname + " " + whoami().lastname}</h1>
			{/* <div className="row-card">
			<MediaCard id={"claim"} title={"Service 2"} subTitle={""} onClick={() => alert("Under construction !")} image={"/public/service_2.png"}/>
			<MediaCard id={"benefits"} title={"Service 1"} subTitle={""} onClick={() => alert("Under construction !")} image={"/public/service_1.png"}/>
			</div> */}
			</>
	    );
	}
}