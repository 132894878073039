import { rest } from "../services";

class MetadataLoader {
    
    constructor() {
        if (!!MetadataLoader.instance) {
            return MetadataLoader.instance;
        }
        MetadataLoader.instance = this;
        this.metadata = {};
        return this;
    }    
    
	load = async (entity) => {
		let filter = {};
       	filter[entity] = {};
        if(!this.metadata[entity])
            this.metadata[entity] = {}
       	return rest.search(entity, filter)
            .then(response => {
                response.forEach(element => {
                    this.metadata[entity][element['id']] = element['name'];
             });
        })
    }
    
    get = (entity) => {
    	return this.metadata[entity];
    }
    

}

export const metadataLoader = new MetadataLoader();

export function getServiceUri() {
    return '/api/';
}