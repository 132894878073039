import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import { SIDE_MENU } from '../../../auto/js/metadata/MenuType';
import {whoami} from '../../../auto/js/users/UserInfo';
import { displayCivilRecordAdminInfo } from '../../../auto/js/lists/CivilRecordAdminInfo'

import { faBoxArchive, faGear, faHouse } from '@fortawesome/pro-regular-svg-icons';
import { t } from '../../../auto/js/services';

export const menu = (gui) => {
    return {
        "home": {
            label: "Home",
            do: () => gui.goTo(gui.readWelcomePage),
            icon: <FontAwesomeIcon icon={faHouse} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Home`} />
        },
        /* ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "registro": {
                submenu: {
                    "registro": {
                        options: {
                            import: {
                                label: "Import",
                                do: () => displayImportRecordForm(gui)
                            },
                            retornados: {
                                label: "Retornados",
                                do: () => displayRetornadosRecordList(gui)
                            }
                        }
                    }
                },
                label: "Registro",
                icon: <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
			},
        }), */
        "my-addresses": {
            label: "My Addresses",
            do: () => displayCivilRecordAdminInfo(gui, whoami().vitalRecordId),
            icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
        },
        /* ...((whoami().roles.includes('ALCALDIA')) && {
                "my-cases": {
                    label: "My Cases",
                    do: () => displayEducationAffiliationList(gui),
                    icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
                }
        }), */
        /* ...((whoami().roles.includes('CP')) && {
            "my-cases": {
                label: "My Cases",
                do: () => displayHousingAffiliationList(gui),
                icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
            }
        }), */
        /* ...((whoami().roles.includes('PNUD')) && {
            "my-cases": {
                label: "My Cases",
                do: () => displayEmployAffiliationList(gui),
                icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
            }
        }), */
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('ALCALDIA') || whoami().roles.includes('ADRES') || whoami().roles.includes('PNUD') || whoami().roles.includes('CP')) && {
            "programs": {
                submenu: {
                    "programs": {
                        options: {
                            list: {label: "Programs", do: () => displayProgramList(gui)},
                            new: {label: "New Program", do: () => gui.goTo(displayNewProgramForm())},
                        },
                        label: "Programs"
                    },
                },
                label: "Program",
                icon: <FontAwesomeIcon icon={faHandsHolding} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Programs`} />
			},
        }), */
        /* ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "house-hold": {
                submenu: {
                    "house-holds": {
                        options: {
                            list: {label: "House Holds", do: () => displayHouseHoldList(gui)},
                            new: {label: "New House Hold", do: () => displayNewHouseHoldForm(gui)},
                        },
                        label: "House Holds"
                    },
                },
                label: "House Hold",
                icon: <FontAwesomeIcon icon={faPeopleRoof} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`House Hold`} />
            }
        }), */
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "configuration": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: () => displayTranslationsList(gui)},
                            new: {label: "New Translation", do: () => displayNewTranslationsForm(gui)},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration",
                icon: <FontAwesomeIcon icon={faGear} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Configurations`} />
            }
        }) */
    }
}
export const menuType = SIDE_MENU;
