import React, { forwardRef } from 'react';
import MaterialTable, { MTablePagination, MTableToolbar } from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { i18ndb, t, rest } from '../services';
import { getEntityIdCol, n2nRelation, pojoMetadata, relationMetadata } from "../metadata";

import { selectLocationState } from 'redux-first-router';
import { loadingIcon } from '../loadingIcon';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';

export class RelationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      joinedData: undefined,
      data: undefined,
      role: undefined,
      rowData: undefined,
      selectedRows: (props.selectedRows) ? props.selectedRows : [],
      initialSelectedRows: [],
      updatedSelection: [],
      deleteSelection: [],
      page: 0,
      dataCount: undefined,
      selectedCount: undefined,
      dataPerPage: 20
    }
    this.tableRef = React.createRef();
    if (props.listenTo)
      props.listenTo.subscribe(this);

    this.handleChange = this.handleChange.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.getRowNumber = this.getRowNumber.bind(this);
  }

  filterRows = (dataList, joinedDataList) => {
    let rows = [];
    dataList.forEach(element => {
      joinedDataList.forEach(row => {
        if (element[getEntityIdCol(this.props.target)] == row[getEntityIdCol(this.props.target)]) {
          element = { ...element, ...row };
        }
      })
      rows.push(element);
    });
    return rows;
  }

  handleChange = (page) => {
    this.setState({ page: page });
  }

  getRowNumber = () => {
    if (this.props.selected)
      return this.state.selectedCount;
    else
      return this.state.dataCount;
  }

  process = (data) => {
    this.setState({ selectedRows: data['selectedRows'], initialSelectedRows: data['initialSelection'], updatedSelection: data['updatedSelection'], selectedCount: data['selectedRows'].length });
    (this.tableRef.current != null) ? this.tableRef.current.dataManager.changePageSize((data['selectedRows'].length < 20) ? data['selectedRows'].length : 20) : null
  }

  render() {
    let rows = [];
    let selection = [];
    if (!this.state.data && !this.state.joinedData && !this.state.role) {
      let item = {};
      n2nRelation[this.props.entity][this.props.target].columns.forEach(element => {
        if (element.type == 'select') {
          let filter = {};
          filter[element.header.key] = {};
          rest.search(element.header.key, filter)
            .then(response => {
              for (let row of response) {
                item[row[element.key]] = row[element.value]
              }
              this.setState({ role: item })
            })
        }
        if (element.type == 'input') {
          rest.count(this.props.target).then(
            response => {
              this.setState({ dataCount: response })
            }
          )
          let filterTarget = {};
          filterTarget[this.props.target] = {};
          if (this.props.searchFilter) {
            filterTarget['and'] = false;
            pojoMetadata[this.props.target].columns.forEach(element => {
              if (element.type == 'text' && this.props.searchFilter != '') {
                filterTarget[this.props.target][element.key] = this.props.searchFilter;
              }
            });
          }
          rest.search(this.props.target, (this.props.advancedSearchFilter) ? this.props.advancedSearchFilter : filterTarget)
            .then(response => {
              this.setState({ data: response })
            })
          let filterJoin = {};
          rest.join(this.props.entity, this.props.target, filterJoin, this.props.id)
            .then(response => {
              this.setState({ joinedData: response })
            })
        }
      })
    }
    if (this.state.joinedData && this.state.data && this.state.role && !this.state.rowData && !this.state.selectedCount) {
      let rowsLayout = [];
      if (this.props.selected)
        rowsLayout = this.state.joinedData;
      else
        rowsLayout = this.filterRows(this.state.data, this.state.joinedData);
      for (let index in rowsLayout) {
        rows.push(n2nRelation[this.props.entity][this.props.target].row(index, rowsLayout, this.props.id))
      }

      if (this.props.selection)
        for (let i in rows) {
          this.state.selectedRows.forEach(element => {
            if ((rows[i][relationMetadata[this.props.entity + "-" + this.props.target][0]] === element[relationMetadata[this.props.entity + "-" + this.props.target][0]]) && ((rows[i][relationMetadata[this.props.entity + "-" + this.props.target][1]] === element[relationMetadata[this.props.entity + "-" + this.props.target][1]]))) {
              rows[i] = element;
            }
              
          })
        }

      this.setState({ rowData: rows });
      for (let i in rows) {
        if (rows[i].tableData['checked'] == true) {
          if (this.props.selected)
            rows[i].tableData['checked'] = false
          selection.push(rows[i])
        }
      }

      this.setState({ selectedRows: (this.props.selectedRows) ? this.props.selectedRows : selection });
      this.setState({ selectedCount: selection.length })
      this.setState({ initialSelectedRows: selection });
    }
    const columns = []
    n2nRelation[this.props.entity][this.props.target].columns.forEach(column => {
      if (column.type == 'select') {
        columns.push({
          title: i18ndb._(column.header.name), field: column.field, lookup: this.state.role
        })
      }
      else {
        columns.push({
          title: i18ndb._(column.header.name), field: column.header.key, editable: column.editable, type: column.type
        })
      }
    })
    
    if (this.props.selected) {
      for (let i in this.state.selectedRows) {
        this.state.selectedRows[i].tableData['checked'] = false;
        this.state.selectedRows[i].tableData['customChecked'] = true;
      }
    }

    if (this.props.selection)
      for (let i in this.props.selectedRows)
        this.props.selectedRows[i].tableData['checked'] = true

    this.props.callbackFromParent(this.state.selectedRows, this.state.initialSelectedRows, this.state.updatedSelection, this.state.deleteSelection);

    if (this.getRowNumber() === undefined)
      return loadingIcon;
    let cssProperties = {}
    if (this.props.language === "ar")
      cssProperties["direction"] = 'rtl';
    else
      cssProperties["direction"] = 'ltr';

    return (
      <div className="table" style={cssProperties}>
        <span>{t`selected`} {this.state.selectedRows.length}</span>
        <MaterialTable
          tableRef={this.tableRef}
          columns={columns}
          data={(this.props.selected) ? this.state.selectedRows : this.state.rowData}
          title={i18ndb._(n2nRelation[this.props.entity][this.props.target]['title'])}
          onSelectionChange={(selections) => {
            this.setState({ selectedRows: selections, selectedCount: selections.length + this.state.selectedCount })
          }}
          editable={{
            onRowUpdate: (!this.props.selected) ?
              (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const index = oldData.tableData.id;
                  newData['tableData'] = oldData.tableData;
                  newData.tableData.editing = undefined;
                  this.setState(prevState => {
                    const rows = [...prevState.rowData];
                    const selected = [...prevState.selectedRows]
                    let selectedIndex = selected.findIndex(x => x.tableData.id === index);
                    rows[index] = newData;
                    {
                      (newData.tableData.checked) ?
                      selected[selectedIndex] = newData :
                      null
                    }
                    return { rowData: rows, selectedRows: selected }
                  });
                  if (this.state.initialSelectedRows.length !== 0) {
                    for (let i in this.state.initialSelectedRows) {
                      if (oldData === this.state.initialSelectedRows[i]) {
                        this.setState(prevState => {
                          let updated = [...prevState.updatedSelection];
                          (newData[getEntityIdCol(n2nRelation[this.props.entity][this.props.target]['relation'].name) !== null]) ? updated.push(newData) : null;
                          return { updatedSelection: updated };
                        })
                      }
                    }
                  }
                  else {
                    this.setState(prevState => {
                      let selection = [...prevState.selectedRows];
                      for (let i in selection) {
                        if (selection[i] === oldData) {
                          selection[i] = newData;
                        }
                      }
                      return { selectedRows: selection }
                    })
                  }
                  resolve()
                }) : null,
            onRowDelete: (this.props.delete) ? oldData =>
              new Promise((resolve, reject) => {
                this.setState(prevState => {
                  let dataDelete = [...prevState.selectedRows];
                  let deleteRows = [...prevState.deleteSelection];
                  const index = dataDelete.indexOf(oldData);
                  deleteRows.push(dataDelete[index])
                  dataDelete.splice(index, 1);
                  return { selectedRows: dataDelete, deleteSelection: deleteRows }
                })
                resolve()
              }) : null
          }}
          options={{
            actionsColumnIndex: -1,
            search: true,
            selection: (this.props.selection) ? this.props.selection : false,
            showTextRowsSelected: false,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF',
              textAlign: (this.props.language == "ar") ? 'right' : 'left'
            },
            cellStyle: {
              textAlign: (this.props.language == "ar") ? 'right' : 'left'
            },
            paging: true,
            pageSize: (this.getRowNumber() < 20) ? this.getRowNumber() : 20,
          }}
          localization={{
            // for more https://material-table.com/#/docs/features/localization
            header: {
              actions: t`Actions`
            },
            body: {
              emptyDataSourceMessage: t`no data`,
              addTooltip: t`Add`,
              deleteTooltip: t`Delete`,
              editTooltip: t`Edit`,
              editRow: {
                deleteText: t`Delete this item?`,
                cancelTooltip: t`cancel`,
              }
            },
            toolbar: {
              searchTooltip: t`Search`,
              searchPlaceholder: t`Search`
            },
            pagination: {
              labelRowsSelect: t`row_pk`,
              labelDisplayedRows: '{from}-{to} ' + t`of` + ' {count}',
              firstTooltip: t`first page`,
              previousTooltip: t`previous`,
              nextTooltip: t`next`,
              lastTooltip: t`last page`
            }
          }}
          icons={{
            Add: props => <FontAwesomeIcon icon="edit" {...props} />,
            FirstPage: forwardRef((iconProps, ref) => (this.props.language == "ar") ? <FirstPage {...iconProps} ref={ref} /> : <LastPage {...iconProps} ref={ref} />),
            LastPage: forwardRef((iconProps, ref) => (this.props.language == "ar") ? <LastPage {...iconProps} ref={ref} /> : <FirstPage {...iconProps} ref={ref} />),
            NextPage: forwardRef((iconProps, ref) => (this.props.language == "ar") ? <ChevronRight {...iconProps} ref={ref} /> : <ChevronLeft {...iconProps} ref={ref} />),
            PreviousPage: forwardRef((iconProps, ref) => (this.props.language == "ar") ? <ChevronLeft {...iconProps} ref={ref} /> : <ChevronRight {...iconProps} ref={ref} />),
          }}
          actions={[
            (this.props.add) ?
              {
                icon: "edit",
                tooltip: t`Edit`,
                position: "toolbar",
                isFreeAction: true,
                onClick: this.props.add
              } : null
          ]}
        />
      </div>
    )
  }


}