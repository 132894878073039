import React from "react";
import {v4 as uuidv4} from 'uuid';
import { rest } from "../services/RestClient";
import { t } from "../services/i18ndb";
import { pojoMetadata } from "../metadata";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTableComponent } from "../widgets/TableComponent";

import { addressFields, countAddressData, filterData, form2Dto, getAddressData, loadGeoData } from "./AddressList";
import { getServiceUri } from "../metadata";

export class AdministrativeUpdateApplicationFormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
        let AddressList = createTableComponent(addressFields);
	
		return (
		<>
			<Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>{t`Address`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddressList key={this.props.key} loadData={async (query) => buildAddressData(query, this.props.id)}  editable={getAddressEditables(this.props.id)}/>
                </AccordionDetails>
            </Accordion>
		</>
		)
	}

}

export const displayCivilRecordAdminInfo = (gui, id) => {
	const readCivilRecordAdminInfo = (onFinish) => (id) => {
		let uuid = uuidv4();
		return { 
			uuid, view: () => <AdministrativeUpdateApplicationFormComponent key={uuid} id={id} />
		}
	}
    gui.goTo(readCivilRecordAdminInfo(), id)
}

const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {vitalRecordId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getAddressData(filter).then(response => {
        data = filterData(response);
        data.forEach(element => {
            loadGeoData(element);
        });
        return countAddressData(data).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

export const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {

            let dto = pojoMetadata['address'].form2dto(newData);
            dto["vitalRecordId"] = id;
            form2Dto(dto, newData);
            try {
                return rest.request(getServiceUri() + "/address-service/add-address", "POST", dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }
        }),
        editables.onRowUpdate = (newData, oldData) =>
            new Promise((resolve, reject) => {
                let dto = pojoMetadata['address'].form2dto(newData);
                newData.id = oldData.id;
            	form2Dto(dto, newData);
                try {
                    return rest.request(getServiceUri() + "/address-service/update-address", "PUT", dto).then(() => {
                        resolve()}).catch((e) => console.table(e))

                } catch (err) {
                    alert(err);
                }
            }),
        editables.onRowDelete = oldData =>
            new Promise((resolve, reject) => {
                try {
                    return rest.delete("/address-service/delete-address/", oldData.id).then(() => resolve());
                } catch (err) {
                    alert(err);
                }
            })
    return editables;
}
