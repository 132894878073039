import React from 'react';

import styled from 'styled-components';
import { createBrowserComponent } from './Browser';
import { ToolBar } from '../widgets/ToolBar';
import { SideMenu } from '../widgets/SideMenu';

const Main = styled.main`
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
	height: calc(100vh - 100px);
`;

export class SideMenuBrowser extends createBrowserComponent() {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <>
            <div className="row">
                <div class="col-md-1">
                    <SideMenu gui={this}/>
                </div>
                <div class="col-md-1" style={{ backgroundColor: '#0468b1', right: '-66px' }}>
                </div>
                <div className='col-md-10' style={{ backgroundColor: '#ffffff', zIndex: '1111', borderRadius: '60px 0px 0px 60px' }}>
                    <div className='box'>
                        <div className='bg'>
                            {/* <ToolBar languages={this.props.supportedLanguages}/> */}
                            <Main>
                            {this.buildDesktop()}
                            </Main>
                        </div>
                    </div>
                </div>
            </div>	
            </>
        )
    }
}