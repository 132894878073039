import * as React from 'react';
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel } from '@mui/material';

export function TextInput (props) {
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
  const { setValue } = helpers;
  const handleChange = event => {
	setValue(event.target.value);
  };
	return (
		<FormControl variant="standard">
		<InputLabel shrink htmlFor={props.name}>
			{props.label}
        </InputLabel>
		<TextField
		id={props.name}
		label={props.label}
		multiline
		rowsMax="4"
		value={value}
		onChange={handleChange}
		type={props.type}
		disabled={props.disabled}
		InputLabelProps={{shrink: false}}
		/>
		</FormControl>
	);
}