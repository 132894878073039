import React, { useState } from 'react';

import { Sidebar, Menu, MenuItem, SubMenu, ProSidebarProvider } from 'react-pro-sidebar';

import './sidebar.scss';
import { i18ndb, rest, t } from '../../../auto/js/services';
import { menu } from '../../../main/js/router/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { SidebarHeader } from '../../../auto/js/widgets/SidebarHeader';
import { SidebarFooter } from '../../../auto/js/widgets/SidebarFooter';


const menuItemStyles = {
  SubMenuExpandIcon: {
    color: '#b6b7b9',
  },
  subMenuContent: ({ level }) => ({
    backgroundColor: '#0468b1'
  }),
  button: {
    '&.active': {
      backgroundColor: '#085b99',
      borderRadius: '30px'
    },
    '&:hover': {
      backgroundColor: '#085b99',
      color: '#fff',
      borderRadius: '30px'
    },
  }
};


const buildHeaderContent = () => {
  return (
    <div
      style={{
        padding: '9px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 15,
        letterSpacing: '1px'
      }}
    >
      {t`Profile`}
    </div>
  )
}

const buildFooterContent = () => {
  return (
    <>
    <div className="sidebar-btn-wrapper" style={{ paddingLeft: '10px', paddingTop: '20px', width: '240px' }}>  
    <span>{t`Options`}</span>
    </div>
    <Button
        color="inherit"
        style={{paddingLeft: '10px'}}
        onClick={() => {rest.oidcService.logout();}}>
        <FontAwesomeIcon icon="arrow-right-from-bracket" style={{ fontSize: '1.75em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Sign out`} />
        {t`Sign out`}
    </Button>
    </>
  )
}

export const SideMenu = ({gui}) => {
  const [activeIndex, setActiveIndex] = useState({parentIndex: null, index: 0});

  const onItemClick = (parentIndex, index, descriptorDo) => {
    descriptorDo();
    setActiveIndex({parentIndex: parentIndex, index: index});
  }

  const buildItemList = (subMenu, parentIndex) => {
    let items = [];
    const subMenuKeys = Object.keys(subMenu);
    subMenuKeys.forEach((key) => {
        let options = subMenu[key].options;
        let optionsKeys = Object.keys(options);
        optionsKeys.forEach((key, index) => {
          items.push(
          <MenuItem active={(parentIndex === activeIndex.parentIndex && index === activeIndex.index)} onClick={() => onItemClick(parentIndex, index, options[key].do)}> {i18ndb._(options[key].label)} </MenuItem>
          )
        });
    });
    return items;
  }
  
  const buildMenu = (options) => {
    const items = []
    const optionsKeys = Object.keys(options);
    optionsKeys.forEach((key, index) => {
      if (options[key]["submenu"])
        items.push(
          <SubMenu label={i18ndb._(options[key].label)}>
           {buildItemList(options[key]["submenu"], index)}
          </SubMenu>
        )
      else
        items.push(
          <MenuItem active={(activeIndex.parentIndex === null && index === activeIndex.index)} onClick={() => onItemClick(null, index, options[key].do)}> {i18ndb._(options[key].label)} </MenuItem>
        )
    });
    return items;
  }

    return (
      <div style={{ display: 'flex', height: '100%' }}>
      <ProSidebarProvider>
        <Sidebar
          backgroundColor={'#0468b1'}
          rootStyles={{
            color: '#ffffff',
          }}
        >
        {/* Header */}
        <SidebarHeader children={buildHeaderContent()} />
        {/* Content */}
          <Menu menuItemStyles={menuItemStyles}>
            {buildMenu(menu(gui))}
          </Menu>
        {/* Footer */}
        <SidebarFooter  children={buildFooterContent()} />
      </Sidebar>
    </ProSidebarProvider>
    </div>
    )
}
