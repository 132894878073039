import * as React from 'react';
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";

export function PasswordInput (props) {
	const [field, meta, helpers] = useField(props);
	const { value } = meta;
  const { setValue } = helpers;
  const handleChange = event => {
    setValue(event.target.value);
  };
	return (
		<TextField
		id={props.name}
		label={props.label}
		value={value}
		onChange={handleChange}
		type="password"
	/>
	);
}
