import * as React from 'react';
import Noty from "noty";
import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

export const showNotification = (text, type) => {
	new Noty({
		text,
		type: type,
		theme: 'relax',
		timeout: 3000,
	}).show();
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export let ddmap = ( k, v ) => ( { key: k, value: v } );

export let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
		return data[a].localeCompare( data[b] ) 
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}

export const useConstructor = (callBack = () => {}) => {
	const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
	if (hasBeenCalled) return;
	callBack();
	setHasBeenCalled(true);
}

export const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export const formatDateTime = (date) => {
	let d = new Date(date[0], date[1]-1, date[2], date[3], date[4], date[5]);
	return d.toLocaleDateString('en-us') + " " + d.toLocaleTimeString('en-us');
}


export function parseHashArguments(url, subcommandOptions, excludedArguments) {
  const hashIndex = url.indexOf('#');
  if (hashIndex === -1) {
    return {}; // No hash arguments found
  }

  const hash = url.slice(hashIndex + 1);
  if (!hash) {
    return {}; // Empty hash, return an empty object
  }

  const subcommandRegex = /^(\w+):/;
  const subcommandMatch = hash.match(subcommandRegex);

  const subcommand = subcommandMatch ? subcommandMatch[1] : undefined;
  const argsMap = {};

  const hashArgs = subcommandMatch ? hash.slice(subcommandMatch[0].length) : hash;

  hashArgs.split('&').forEach((arg) => {
    const [key, value] = arg.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = value ? decodeURIComponent(value) : true;

    if (!excludedArguments.includes(decodedKey)) {
      if (subcommand && subcommandOptions[subcommand].hasOwnProperty(decodedKey)) {
        switch (subcommandOptions[subcommand][decodedKey]) {
          case 'string':
            argsMap[decodedKey] = decodedValue.toString();
            break;
          case 'number':
            argsMap[decodedKey] = !isNaN(decodedValue) ? Number(decodedValue) : undefined;
            break;
          case 'boolean':
            argsMap[decodedKey] = true;
            break;
          default:
            throw new Error(`Invalid type for argument '${decodedKey}' in subcommand '${subcommand}'`);
        }
      } else if (!subcommand) {
        argsMap[decodedKey] = decodedValue;
      }
    }
  });

  return { subcommand, argsMap };
}

export const swapObject = (object) => {
  let swappedObject = {};

  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      swappedObject[parseInt(object[key])] = key;
    }
  }
  return swappedObject;
}
