import PropTypes from 'prop-types';
import React from 'react';

import { t } from "../services";

import loadImageURL from '../utils/load-image-url'

const makeCancelable = promise => {
	let hasCanceled_ = false

	const wrappedPromise = new Promise((resolve, reject) => {
		/* eslint-disable prefer-promise-reject-errors */
		promise.then(
			val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
			error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
		)
		/* eslint-enable */
	})

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true
		},
	}
}

let pixelRatio =
	typeof window !== 'undefined' && window.devicePixelRatio
		? window.devicePixelRatio
		: 1

// Draws a rounded rectangle on a 2D context.
const drawRoundedRect = (context, x, y, width, height, borderRadius) => {
	if (borderRadius === 0) {
		context.rect(x, y, width, height)
	} else {
		const widthMinusRad = width - borderRadius
		const heightMinusRad = height - borderRadius
		context.translate(x, y)
		context.arc(
			borderRadius,
			borderRadius,
			borderRadius,
			Math.PI,
			Math.PI * 1.5
		)
		context.lineTo(widthMinusRad, 0)
		context.arc(
			widthMinusRad,
			borderRadius,
			borderRadius,
			Math.PI * 1.5,
			Math.PI * 2
		)
		context.lineTo(width, heightMinusRad)
		context.arc(
			widthMinusRad,
			heightMinusRad,
			borderRadius,
			Math.PI * 2,
			Math.PI * 0.5
		)
		context.lineTo(borderRadius, height)
		context.arc(
			borderRadius,
			heightMinusRad,
			borderRadius,
			Math.PI * 0.5,
			Math.PI
		)
		context.translate(-x, -y)
	}
}

/**
	This code will use "image" for the avatar image type,
	which contains {x, y, rotate, scale, url}, and "img" 
	for the HTML/DOM element img
 */
export class ImageViewer extends React.Component {
	static propTypes = {
		image: PropTypes.shape({
			x: PropTypes.number,
			y: PropTypes.number,
			rotate: PropTypes.number,
			scale: PropTypes.number,
			url: PropTypes.string
		}),
		border: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.arrayOf(PropTypes.number),
		]),
		borderRadius: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		color: PropTypes.arrayOf(PropTypes.number),
		crossOrigin: PropTypes.oneOf(['', 'anonymous', 'use-credentials']),

		onLoadFailure: PropTypes.func,
		onLoadSuccess: PropTypes.func,
		onImageReady: PropTypes.func,
		disableBoundaryChecks: PropTypes.bool,
		disableHiDPIScaling: PropTypes.bool,
	}

	static defaultProps = {
		border: 0,
		borderRadius: 0,
		width: 128,
		height: 128,
		color: [255, 255, 255, 0.6], // RGBA
		onLoadFailure() { },
		onLoadSuccess() { },
		onImageReady() { },
		disableBoundaryChecks: false,
		disableHiDPIScaling: false,
	}
	
	constructor(props) {
		super(props)
		this.canvas = null
	}

	state = {
		img: {
			x: 0.5,
			y: 0.5
		},
	  }

	componentDidMount() {
		// scaling by the devicePixelRatio can impact performance on mobile as it creates a very large canvas. This is an override to increase performance.
		if (this.props.disableHiDPIScaling) {
			pixelRatio = 1
		}
		const context = this.canvas.getContext('2d')
		if (this.props.image.url) {
			this.loadImage(this.props.image)
		}
		this.paint(context)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(this.props.image && this.props.image !== prevProps.image) ||
			this.props.width !== prevProps.width ||
			this.props.height !== prevProps.height
		) {
			this.loadImage(this.props.image)
		} else if (!this.props.image && !prevState.img) {
			this.clearImage()
		}

		const context = this.canvas.getContext('2d')
		context.clearRect(0, 0, this.canvas.width, this.canvas.height)
		this.paint(context)
		this.paintImage(context, this.state.img, this.props.border)
	}

	isVertical() {
		return this.props.image.rotate % 180 !== 0
	}

	getBorders(border = this.props.border) {
		return Array.isArray(border) ? border : [border, border]
	}

	getDimensions() {
		const { width, height, border } = this.props

		const canvas = {}

		const [borderX, borderY] = this.getBorders(border)

		const canvasWidth = width
		const canvasHeight = height

		if (this.isVertical()) {
			canvas.width = canvasHeight
			canvas.height = canvasWidth
		} else {
			canvas.width = canvasWidth
			canvas.height = canvasHeight
		}

		canvas.width += borderX * 2
		canvas.height += borderY * 2

		return {
			canvas,
			rotate: this.props.image.rotate,
			width,
			height,
			border,
		}
	}

	getImage() {
		// get relative coordinates (0 to 1)
		const cropRect = this.getCroppingRect()
		const img = this.state.img

		// get actual pixel coordinates
		cropRect.x *= img.resource.width
		cropRect.y *= img.resource.height
		cropRect.width *= img.resource.width
		cropRect.height *= img.resource.height

		// create a canvas with the correct dimensions
		const canvas = document.createElement('canvas')

		if (this.isVertical()) {
			canvas.width = cropRect.height
			canvas.height = cropRect.width
		} else {
			canvas.width = cropRect.width
			canvas.height = cropRect.height
		}

		// draw the full-size image at the correct position,
		// the image gets truncated to the size of the canvas.
		const context = canvas.getContext('2d')

		context.translate(canvas.width / 2, canvas.height / 2)
		context.rotate((this.props.image.rotate * Math.PI) / 180)
		context.translate(-(canvas.width / 2), -(canvas.height / 2))

		if (this.isVertical()) {
			context.translate(
				(canvas.width - canvas.height) / 2,
				(canvas.height - canvas.width) / 2
			)
		}
		context.drawImage(img.resource, -cropRect.x, -cropRect.y)

		return canvas
	}

	getXScale() {
		const canvasAspect = this.props.width / this.props.height
		const imageAspect = this.state.img.width / this.state.img.height

		return Math.min(1, canvasAspect / imageAspect)
	}

	getYScale() {
		const canvasAspect = this.props.height / this.props.width
		const imageAspect = this.state.img.height / this.state.img.width

		return Math.min(1, canvasAspect / imageAspect)
	}

	getCroppingRect() {
		const position = (this.props.image)?{
			x: this.props.image.x,
			y: this.props.image.y} : {
			x: this.state.img.x,
			y: this.state.img.y,
		}
		const width = (1 / this.props.image.scale) * this.getXScale()
		const height = (1 / this.props.image.scale) * this.getYScale()

		const croppingRect = {
			x: position.x - width / 2,
			y: position.y - height / 2,
			width,
			height,
		}

		let xMin = 0
		let xMax = 1 - croppingRect.width
		let yMin = 0
		let yMax = 1 - croppingRect.height

		// If the cropping rect is larger than the image, then we need to change
		// our maxima & minima for x & y to allow the image to appear anywhere up
		// to the very edge of the cropping rect.
		const isLargerThanImage =
			this.props.disableBoundaryChecks || width > 1 || height > 1

		if (isLargerThanImage) {
			xMin = -croppingRect.width
			xMax = 1
			yMin = -croppingRect.height
			yMax = 1
		}
		return {
			...croppingRect,
			x: Math.max(xMin, Math.min(croppingRect.x, xMax)),
			y: Math.max(yMin, Math.min(croppingRect.y, yMax)),
		}
	}

	loadImage(image) {
		if (image && image.url) {
			this.loadingImage = makeCancelable(
				loadImageURL(image.url, this.props.crossOrigin)
			)
				.promise.then(this.handleImageReady)
				.catch(this.props.onLoadFailure)
		}
	}

	handleImageReady = img => {
		const imageState = this.getInitialSize(img.width, img.height)
		imageState.resource = img
		imageState.x = this.props.image.x
		imageState.y = this.props.image.y
		this.setState({ img: imageState })
	}

	getInitialSize(width, height) {
		let newHeight
		let newWidth

		const dimensions = this.getDimensions()
		const canvasRatio = dimensions.height / dimensions.width
		const imageRatio = height / width

		if (canvasRatio > imageRatio) {
			newHeight = this.getDimensions().height
			newWidth = width * (newHeight / height)
		} else {
			newWidth = this.getDimensions().width
			newHeight = height * (newWidth / width)
		}

		return {
			height: newHeight,
			width: newWidth,
		}
	}

	clearImage = () => {
		const context = this.canvas.getContext('2d')
		context.clearRect(0, 0, this.canvas.width, this.canvas.height)
	}

	paintImage(context, img, border, scaleFactor = pixelRatio) {
		if (img.resource) {
			const position = this.calculatePosition(img, border)

			context.save()

			context.translate(context.canvas.width / 2, context.canvas.height / 2)
			context.rotate((this.props.image.rotate * Math.PI) / 180)
			context.translate(
				-(context.canvas.width / 2),
				-(context.canvas.height / 2)
			)

			if (this.isVertical()) {
				context.translate(
					(context.canvas.width - context.canvas.height) / 2,
					(context.canvas.height - context.canvas.width) / 2
				)
			}

			context.scale(scaleFactor, scaleFactor)

			context.globalCompositeOperation = 'destination-over'
			context.drawImage(
				img.resource,
				position.x,
				position.y,
				position.width,
				position.height
			)

			context.restore()
		}
	}

	calculatePosition(img, border) {
		img = img || this.state.img

		const [borderX, borderY] = this.getBorders(border)

		const croppingRect = this.getCroppingRect()

		const width = img.width * this.props.image.scale
		const height = img.height * this.props.image.scale

		let x = -croppingRect.x * width
		let y = -croppingRect.y * height

		if (this.isVertical()) {
			x += borderY
			y += borderX
		} else {
			x += borderX
			y += borderY
		}

		return {
			x,
			y,
			height,
			width,
		}
	}

	paint(context) {
		context.save()
		context.scale(pixelRatio, pixelRatio)
		context.translate(0, 0)
		context.fillStyle = 'rgba(' + this.props.color.slice(0, 4).join(',') + ')'

		let borderRadius = this.props.borderRadius
		const dimensions = this.getDimensions()
		const [borderSizeX, borderSizeY] = this.getBorders(dimensions.border)
		const height = dimensions.canvas.height
		const width = dimensions.canvas.width

		// clamp border radius between zero (perfect rectangle) and half the size without borders (perfect circle or "pill")
		borderRadius = Math.max(borderRadius, 0)
		borderRadius = Math.min(
			borderRadius,
			width / 2 - borderSizeX,
			height / 2 - borderSizeY
		)

		context.beginPath()
		// inner rect, possibly rounded
		drawRoundedRect(
			context,
			borderSizeX,
			borderSizeY,
			width - borderSizeX * 2,
			height - borderSizeY * 2,
			borderRadius
		)
		context.rect(width, 0, -width, height) // outer rect, drawn "counterclockwise"
		context.fill('evenodd')

		context.restore()
	}

	setCanvas = canvas => {
		this.canvas = canvas
	}

	render() {
		
		if (!this.props.image)
			return;

		const dimensions = this.getDimensions();
		
		const defaultStyle = {
			width: dimensions.canvas.width,
			height: dimensions.canvas.height,
			cursor: (this.props.onClick) ? 'pointer' : '',
			touchAction: 'none',
			borderRadius: (this.props.borderRadiusStyle)?this.props.borderRadiusStyle:'100px',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: '20px',
			display: 'block',
			position: 'relative',
		}
		
		const attributes = {
			width: dimensions.canvas.width * pixelRatio,
			height: dimensions.canvas.height * pixelRatio,
			style: {
				...defaultStyle,
			},
		}

		return (
			<div>
				<canvas id="image" ref={this.setCanvas} {...attributes} onClick={(this.props.onClick) ?this.props.onClick:''} />
				<label
					for="image"
					style={{
						width: "100px",
						marginLeft: "auto",
						marginRight: "auto",
						display: (this.props.onClick) ? "block" : "none",
					}}>
					{(this.props.onClick) ? <img alt={t`Click to edit`} style={{width: '40px', position: 'relative', top: '-40px', left: '80px'}} src={'/public/edit-picture.png'} onClick={this.props.onClick}></img> : ''}
				</label>
			</div>
		)
	}
}